.App {
  font-family: 'Myriad Pro Regular', 'Helvetica Neue', 'Arial', 'sans-serif';
  width: 100vw;
}

.bg-red{
  background-color: #d42b1e;
}

.bg-pink{
  background-color: #fef1f1;
}

.bg-silver{
  background-color: #ededed;
}

.tac{
  vertical-align: middle;
}

.introbg{
  background-color: #ededed;
  margin: 0%;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 20px;
}

.inline-block-child {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.articlelist{
  display: block;
}

.languages-list{
  padding: 10px;
  width: auto;
}

.languages-list.container{
  width: auto;
  display:flex;
  justify-content: space-evenly;
}

.language-item-div{
  display: inline-block;
  width: 90%;
  margin: 5px;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 5px;
  background-color: #fef1f1;
  text-align: center;
}

.language-item{
  font-size: 1.5rem;
  font-weight: 200;
  color: black;
}

.languagePicker{
  margin-top: 30px;
  margin-bottom: 30px;
}

.regionPicker{
  margin-top: 30px;
  margin-bottom: 30px;
}

.downloadbg{
  display: flex;
  justify-content: space-around;
  background-color: #fef1f1;
  margin: 0%;
  padding: 2%;
}

.footer {
  background-color: #ededed;
  padding: 20px;
  margin-top: 10px;
}

@media only screen and (min-width: 1px) {
  .footer{
    display: flex;
    flex-direction: column;
    justify-content: space-between
  }
}

@media only screen and (min-width: 560px) {
  .footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between
  }
}



.about{
  text-align: center;
  color: black;
  background-color: transparent;
}

.footer-flag{
  padding: 10px;
}

.display-4{
  margin-top: 30px;
}

.fullscreen {
  margin: auto;
}


/* @media only screen and (min-width: 1px) {
  // For phones:
  div {width: 70%;}
}

@media only screen and (min-width: 600px) {
  // For tablets:
  div {width: 80%;}
}

@media only screen and (min-width: 768px) {
  // For desktop:
  div {width: 90%;}
}

@media only screen and (min-width: 1024px) {
  // For HD monitors:
  div {width: 100%;}
}
*/




@media screen and (max-width: 1024px) {
  .display-4 {
    font-size: 2.5rem;
  }

  .features {
    margin-top: 20px;
  }
}
