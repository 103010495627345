.header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.sections {
    display: contents;
    padding: 5px;
    padding-left: 20%;
    padding-right: 20%;
}
.bubblesections{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 200px;
    padding-bottom: 3rem !important;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media screen and (max-width: 560px) {
    .bubblesections{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-width: 150px;
        padding-bottom: 3rem !important;
        margin: 5px;
    }


}




.bubble-section-container-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.bubble {
    width: 100px!important;
    height: 100px!important;
    line-height: 100px!important;
    border-radius: 50%;
    text-align: center;
}
@media screen and (max-width: 380px) {
    .bubble {
        width: 60px!important;
        height: 60px!important;
        line-height: 60px!important;
    }
}
.bubble-img {
    height: 50%;
    width: 50%;
}

.bubble:hover {
    box-shadow: 1px 1px 5px black;
}

.section-text {
    color: #d42b1e;
    font-size: 1.2rem;
    font-weight: 600;
}

.section-text:hover {
    color: #333;
    text-decoration: underline;
}
.bubble-section-container {
    margin-top: 5;
    margin-bottom: 3;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
