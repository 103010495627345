.sectionContainer{
    width: auto;
}

.sections {
    margin: 10px;
}

.bubleandtext{
    text-align: justify;
    display: flex;
    width: auto;
    margin: 10px;
    border: 1px solid #ededed;
    border-radius: 5px;
    background-color: #fef1f1;
}

.imagecontainer{
    width: 50px;
    margin-left: 3%;
    margin-right: 3%;
}

.bubble-img{
    object-fit: contain;
}

@media screen and (min-width: 1px) {
    .text-link {
        width: 100%;
        padding: 5px;
        color: #000000;
        font-size: 1.0rem;
        font-weight: 200;
        text-align: left;
    }
}

@media screen and (min-width: 560px) {
    .text-link {
        width: 100%;
        padding: 5px;
        color: #000000;
        font-size: 1.2rem;
        font-weight: 200;
        text-align: left;
    }
}

.text-link-for-dropdown{
    width: 100%;
    padding: 1px;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
}

.text-link:hover {
    color: #000000;
    text-decoration: underline;
}


@media screen and (max-width: 380px) {
    .bubble {
        width: 60px!important;
        height: 60px!important;
        line-height: 60px!important;
    }

    .bubble-img {
        height: 50%;
        width: 50%;
    }
}
