@media only screen and (min-width: 1px) {
    .downloadtext {
        font-family: 'Myriad Pro Regular', 'Helvetica Neue', 'Arial', 'sans-serif';
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        margin-top: 0;
        line-height: 1.2;
        text-align: center;
    }

    .google-badge {
        height: 50px;
    }

    .apple-badge {
        scale: 0.9;
        padding: 10px;
    }
  }

  @media only screen and (min-width: 600px) {
    .downloadtext {
        font-family: 'Myriad Pro Regular', 'Helvetica Neue', 'Arial', 'sans-serif';
        font-size: 1.7rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        margin-top: 0;
        line-height: 1.2;
        text-align: center;
    }

    .google-badge {
        height: 55px;
    }

    .apple-badge {
        scale: 1.0;
        padding: 10px;
    }
  }

  @media only screen and (min-width: 768px) {
    .downloadtext {
        font-family: 'Myriad Pro Regular', 'Helvetica Neue', 'Arial', 'sans-serif';
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        margin-top: 0;
        line-height: 1.2;
        text-align: center;
    }

    .google-badge {
        height: 60px;
    }

    .apple-badge {
        scale: 1.1;
        padding: 10px;
    }
  }
