.introbg{
    background-color: #ededed;
    margin: 0%;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 20px;
  }

.introtext {
    font-size: 1.0rem;
    text-align: center;
}

@media only screen and (min-width: 1px) {
  .introtext {
    font-size: 1.0rem;
    text-align: center;
}
}

@media only screen and (min-width: 600px) {
    .introtext {
        font-size: 1.2rem;
        text-align: center;
    }
}

@media only screen and (min-width: 768px) {
    .introtext {
        font-size: 1.5rem;
        text-align: center;
    }
} 
