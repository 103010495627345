.image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 5px;
    border-color: #333;
    background-color: #fff;
    border-width: 2px;
    overflow: hidden;
}

.dropdown-2 {
    height: 38px;
    line-height: 38px;
}

.custom-toggle {
    height: 100px;
    font-size: 1rem;
    color: white;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
}

.custom-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
