.navbar-light .navbar-brand {
    color: white;
    align-items: baseline;
}
.navbar{
    border-bottom: 2px solid #ededed;
}

.test {
    vertical-align: bottom;
    font-size: 1.6rem;
}

.logoheader{
    width: 100%;
}

.navbar-light .navbar-brand:hover {
    color:#f5f5f5;
}

.nav-link {
    color: black;
    font-size: 2.5rem;
    line-height: normal;
    padding: 0;
}
.nav-link::after{
    opacity: 0%;
}
.globalization{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;

}
/* .basic-nav-dropdown{
    scale: 80%;
    padding-left: 0;
    padding-right: 0;
} */

.dropdown-stuff {
    display: flex;
    font-size: 1em !important;
    align-content: center;
    align-items: center;
}

.dropdown-stuff a {
    font-size: 1em !important;
}
.reachdeck {
    margin-right: 15px;
    zoom: 0.9;
}

.reachdeck:hover {
    margin-right: 15px;
    filter: contrast(0.5);
    zoom: 0.9;
}
