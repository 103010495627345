.article-link {
    padding-top: 0.2rem;
    padding-bottom: 0.4rem;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    border-bottom: 1px solid #fef1f1;
    margin-bottom: 0.2rem;
}

.article-link:hover {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #fef1f1;
}

@media screen and (min-width: 1px) {
    .article-list-item {
        width: 100%;
    }
    .Article-title{
        margin-top: 10px;
        text-align: center;
        color: #d42b1e;
        font-size: 1.2rem !important;
        font-weight: 600;
    }
}
@media screen and (min-width: 560px) {
    .Article-title{
        margin-top: 1rem;
        text-align: center;
        color: #d42b1e;
        font-size: 1.5rem !important;
        font-weight: 600;
    }
}

.article-list-item a {
    text-align: center !important;
}

.article-list-item {
    text-align: center;
    display: flex;
    width: 600px;
    margin: 5px;
    border: 1px solid #ededed;
    border-radius: 5px;
    background-color: #fef1f1;
}

.article-list-row {
    display: flex;
    flex-direction: row;
    justify-content: center;

}
