.header {
    width: auto;
    margin: 10px;
    border: 1px solid #ededed;
    border-radius: 5px;
    background-color: #fef1f1;
    padding: 10px;
    text-align: center;
    color:#000000;
    font-size: 1.2rem !important;
    font-weight: 400;
    font-weight: normal !important;
}

@media screen and (min-width: 1px) {

    .article h1, h2, h3, h4, h5, h6 {
        font-size: 1.0rem;
        font-weight: bold;
        margin-bottom: 1rem;
        margin-top: 1rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }
}

@media screen and (min-width: 560px) {
    .article h1, h2, h3, h4, h5, h6 {
        font-size: 1.0rem;
        font-weight: bold;
        margin-bottom: 1rem;
        margin-top: 1rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }
}

.article p {

    margin: 0.2rem;
}

.article br {
    display: none;
}

.usefullink {
    margin-top: 10px;
}

