.header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sections {
    display: contents;
    padding: 5px;
    padding-left: 20%;
    padding-right: 20%;
}



.bubblesections{
}

.bubble-section-container {
    display: flex;
    flex-direction: column-reverse;
    align-content: flex-end;
    align-items: center;
}
@media screen and (max-width: 380px) {
    .bubble {
        width: 60px!important;
        height: 60px!important;
        line-height: 60px!important;
    }
}

.bubble {
    width: 100px!important;
    height: 100px!important;
    line-height: 100px!important;
    border-radius: 50%;
    text-align: center;
    background-color: #fef1f1;
}


.bubble-img {
    height: 50%;
    width: 50%;
}

.bubble:hover {
    box-shadow: 1px 1px 5px black;
}

@media only screen and (min-width: 1px) {
    .section-text {
        color: #d42b1e;
        font-size: 1rem;
        font-weight: 600;
    }

    .bubble-section-container-row{
        text-align: center;
        justify-content: space-evenly;
        display: flex;
        flex-wrap: wrap;
    }
  }

  @media only screen and (min-width: 560px) {
    .section-text {
        color: #d42b1e;
        font-size: 1.2rem;
        font-weight: 600;
    }

      .bubble-section-container-row{
          text-align: center;
          justify-content: flex-start;
          display: flex;
          flex-wrap: wrap;
      }
  }

.section-text:hover {
    color: #333;
    text-decoration: underline;
}

.ukr {
    height: 100% !important;
    width: 100% !important;
    border-radius: 50%;
}
